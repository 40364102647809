<template>
  <div
    class="max-w-screen-2xl mx-auto bg-slate-200 h-dvh border-slate-300 border"
  >
    <!-- nav bar title-->
    <div
      class="flex items-center justify-between px-4 py-2 bg-white border-b border-gray-200 shadow h-16"
    >
      <div class="flex   justify-start w-full">
        <div class="text-base text-gray-900 font-semibold" @click="backHome">
          <!-- back home button -->
          <div class="flex items-center">
            <i class="fas fa-chevron-left text-xl text-gray-700"></i>
            <span class="ml-2"> </span>
          </div>
        </div>
        <div class="ml-2 text-lg text-gray-900 font-semibold">Buy Package </div>
        
      </div>
    </div>
    <div class="h-6 w-full bg-slate-200">
      <p class="w-full py-1 text-center text-gray-400 text-xs">
        Event ID : {{ eventId }}
      </p>
    </div>

    <slot />
  </div>
</template>

<script lang="ts" setup>
const backHome = () => {
  navigateTo("/");
};

const eventStore = useEventStore();

const eventId = computed(() => eventStore.eventId);
</script>

<style>
</style>